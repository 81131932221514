;(window as any).abfHsfBeforeRequest = function (preHsfJson) {
  const hostMap = {
    pre: "https://pre-missile-dsp.youku.com",
    prod: "https://missile-dsp.youku.com",
  }
  const str1 = preHsfJson.serviceName
  const str2 = preHsfJson.serviceMethod
  let serviceName = str1.match(/([^\.]+)\.api/)?.[1]
  //
  switch (serviceName) {
    case "adgroup":
      serviceName = "group"
      break
    case "adplan":
      serviceName = "plan"
      break
    case "conversion":
      serviceName = "track"
      break
  }
  let serviceMethod = str2.match(/[^~]+/)[0]
  return [
    `${
      hostMap[(window as any).pageVars?.app?.env || "prod"]
    }/api/${serviceName}/${serviceMethod}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: preHsfJson.json[0].value,
    },
    "straight",
  ]
}
;(window as any).abfHsfAfterRequest = function (result) {
  return { ...result }
}
