const loginInfo = (window as any).loginInfo || {}
// 初始化SDK
const aes = new (window as any).AES({
  pid: "ZJSrX2",
  user_type: `mozi_youkudsp`,
  uid: loginInfo?.moziId,
  env: (window as any).pageVars?.app?.env,
  username: loginInfo?.advertiserName,
  advertiserId: loginInfo?.advertiserId,
  version: "1.0.0",
  debug: true,
})
aes.before(aes.log, function (type, data) {
  console.log("acs", data)
  data.uid = loginInfo ? loginInfo?.moziId : null
  data.username = loginInfo ? loginInfo?.advertiserName : null
  data.advertiserId = loginInfo ? loginInfo?.advertiserId : null
  return [type, data]
})
const plugins = aes.use((window as any).AESPluginPV, {
  // 开启history router的监听
  enableHistory: true,
  // 设置page_id的逻辑规则
  //   getPageId: (url) => {
  //     return location.pathname // 新的 page_id
  //   },
})
aes.use([
  (window as any).AESPluginEvent,
  (window as any).AESPluginJSError,
  (window as any).AESPluginAPI,
  (window as any).AESPluginResourceError,
  (window as any).AESPluginPerf,
  (window as any).AESPluginEventTiming,
])
;(window as any).aes = aes
