import { request, Service } from "@ali/abf-base"

// 将一个统一的服务封装成Service使用，这里以VMS获取列表请求示例
// 参考文档：https://yuque.antfin.com/abf/help/xd9gsl
const addPath = "/crm_query"
class VMSService extends Service {
  constructor() {
    super({
      pre: "https://pre-missile-dsp.youku.com",
      prod: "https://missile-dsp.youku.com",
      daily: "https://pre-missile-dsp.youku.com",
    })
  }
  async queryTopTip(data): Promise<any> {
    return await request(
      `${this.origin}/api/plan/queryTopTip`,
      {
        method: "POST",
        withCredentials: true, // 是否携带cookie
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        data,
      },
      "straight"
    )
  }
  async loginOrRegist(): Promise<any> {
    return await request(
      `${this.origin}${addPath}/api/advertiser/loginOrRegist`,
      {
        method: "POST",
        withCredentials: true, // 是否携带cookie
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: {},
      },
      "straight"
    )
  }
  async greyShotModuleList(subAdvertiserId): Promise<any> {
    return await request(
      `${this.origin}/api/advertiser/greyShotModuleList`,
      {
        method: "POST",
        withCredentials: true, // 是否携带cookie
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: {
          subAdvertiserId,
        },
      },
      "straight"
    )
  }

  async loginOut(): Promise<any> {
    return await request(
      `${this.origin}/api/login/possessed_logout`,
      {
        method: "GET",
        withCredentials: true, // 是否携带cookie
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: {},
      },
      "straight"
    )
  }
  
}

const vmsService = new VMSService()
export default vmsService
