import React from 'react';
import Crmlayout from './Crm';
import { render } from 'react-dom';
(window as any).permission = [];
(window as any).DspLayoutInit = (id) => {
  render(
    <Crmlayout menus={(window as any).layoutMenu} pageVars={(window as any).pageVars} />,
    document.getElementById(id),
  )
}

export default Crmlayout;
